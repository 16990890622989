<template>
  <div class="d-flex align-items-center bg min-vh-100">
    <b-container>
      <div v-if="isLoading">
        <OtherLoading />
      </div>
      <b-row class="justify-content-center" v-else>
        <b-col md="9" lg="6" class="login">
          <b-form>
            <div class="text-center img-logo-cover">
              <div
                class="imgLogo"
                v-bind:style="{
                  'background-image': 'url(' + $store.state.logoHomePage + ')',
                }"
              ></div>
            </div>
            <InputText
              class="mt-3 mb-5"
              v-model="form.sales_no"
              textFloat="รหัสพนักงาน"
              placeholder="รหัสพนักงาน"
              type="text"
              name="sales_no"
              isRequired
              :isValidate="$v.form.sales_no.$error"
              :v="$v.form.sales_no"
            />
            <b-row v-if="error != ''" class="m-2 text-center">
              <span class="text-danger w-100">
                {{ error }}
              </span>
            </b-row>
            <b-row class="pt-5">
              <b-col>
                <b-button
                  class="radius-btn bg-main"
                  block
                  :disabled="isLoading"
                  @click="submitForm"
                  >ยืนยัน
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  components: {
    InputText,
    ModalLoading,
  },
  validations() {
    return {
      form: {
        sales_no: { required },
      },
    };
  },
  data() {
    return {
      error: "",
      isLoading: false,
      form: {
        sales_no: "",
        branch_id: 0,
      },
    };
  },
  computed: {
    ...mapGetters({ salesNo: "getSalesNo", branchId: "getBranchId" }),
  },
  created() {
    this.form.branch_id = this.branchId;
  },
  methods: {
    submitForm: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      await this.$store.dispatch("setMainLoading", true);

      await this.axios
        .post(`${this.$baseUrlSaleOrder}/customer/salesNo`, this.form)
        .then(async (data) => {
          this.isDisable = false;
          await this.$store.dispatch("setMainLoading", false);
          if (data.result == 1) {
            await this.$cookies.set("sales_no", data.detail.sales_no);

            // this.successAlert();
            Swal.fire({
              title: "Success !",
              icon: "success",
              confirmButtonText: "Close",
            });
            this.$router.push({ path: `/create-sale-order` }).catch(() => {});
          } else {
            this.isLoading = false;
            this.isLogin = false;
            this.error = data.message;
            this.warningAlert(this.error);
          }
        })
        .catch(function (error) {
          console.log(error);
          this.warningAlert(error.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.min-vh-100 {
  min-height: calc(100vh - 100px) !important;
}
</style>
